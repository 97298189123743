.why-choose-us-container {
  background-color: #29336A !important;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 100px !important;
  height: 310px;
}

.why-choose-us-title {
  text-align: left;
  font-size: 70px;
  color: #ffffff;
  letter-spacing: 5px;
  font-family: 'Libre Baskerville', serif;
}

.why-choose-us-p {
  text-align: left;
  font-size: 31px;
  color: #ffffff;
  margin-top: 18px;
  margin-bottom: 0px;
  font-family: 'Libre Baskerville', serif;
}

.why-choose-us-img {
  width: 100%;
  margin-top: -100px;
  margin-left: 100px;
  animation: swing 1s ease-in-out infinite alternate;
}

@keyframes swing {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}

@media screen and (max-width: 500px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 50px !important;
    height: 515px;
  }

  .why-choose-us-title {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0px;
    font-family: 'Libre Baskerville', serif;
  }

  .why-choose-us-p {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 0px;
    font-family: 'Libre Baskerville', serif;
  }

  .why-choose-us-img {
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 834px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 47px !important;
    height: 840px !important;
  }

  .why-choose-us-title {
    text-align: center !important;
    font-size: 60px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: center !important;
    font-size: 33px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}


@media screen and (min-width: 835px) and (max-width: 1024px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 218px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 40px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
}
  .why-choose-us-p {
    text-align: left !important;
    font-size: 22px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -55px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}

@media screen and (min-width: 1025px) and (max-width: 1060px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 235px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 50px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}
  .why-choose-us-p {
    text-align: left !important;
    font-size: 22px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -55px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}


@media screen and (min-width: 1061px) and (max-width: 1170px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 234px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 54px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}
.why-choose-us-p {
  text-align: left !important;
  font-size: 23px !important;
  color: #ffffff !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  font-family: 'Libre Baskerville', serif !important;
}

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -55px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}

@media screen and (min-width: 1171px) and (max-width: 1210px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 262px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 66px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}
.why-choose-us-p {
  text-align: left !important;
  font-size: 26px !important;
  color: #ffffff !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  font-family: 'Libre Baskerville', serif !important;
}

  .why-choose-us-img {
    width: 86% !important;
    margin-top: -55px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}


@media screen and (min-width: 1211px) and (max-width: 1253px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 290px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 66px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 25px !important;
    color: #ffffff !important;
    margin-top: 35px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 90% !important;
    margin-top: -72px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}


@media screen and (min-width: 1254px) and (max-width: 1280px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 50px !important;
    height: 305px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 66px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 26px !important;
    color: #ffffff !important;
    margin-top: 35px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 90% !important;
    margin-top: -72px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
}


 @media screen and (min-width: 1281px) and (max-width: 1366px) {

  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 310px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 62px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 28px !important;
    color: #ffffff !important;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 84% !important;
    margin-top: -69px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
}
  
 }

@media screen and (min-width: 1367px) and (max-width: 1500px) {

  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 345px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 80px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 32px !important;
    color: #ffffff !important;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -86px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1626px) {

  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 347px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 78px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 34px !important;
    color: #ffffff !important;
    margin-top: 35px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 83% !important;
    margin-top: -94px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}


@media screen and (min-width: 1626px) and (max-width: 1709px) {

  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 395px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 90px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 36px !important;
    color: #ffffff !important;
    margin-top: 47px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -84px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}




@media screen and (min-width: 1710px) and (max-width: 1800px) {
  .why-choose-us-p {
    text-align: left !important;
    font-size: 41px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}

.why-choose-us-title {
  text-align: left !important;
  font-size: 95px !important;
  color: #ffffff !important;
  letter-spacing: 5px !important;
  font-family: 'Libre Baskerville', serif !important;
}

.why-choose-us-container {
  background-color: #29336A !important;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 100px !important;
  height: 395px !important;
}

.why-choose-us-img {
  width: 83%;
  margin-top: -100px;
  margin-left: 100px;
  animation: swing 1s ease-in-out infinite alternate;
}
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 435px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 90px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 43px !important;
    color: #ffffff !important;
    margin-top: 55px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 82% !important;
    margin-top: -90px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}


@media screen and (min-width: 1921px) and (max-width: 2048px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 600px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 128px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 61px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }
}


@media screen and (min-width: 2049px) and (max-width: 2240px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 686px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 134px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 67px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

}

@media screen and (min-width: 2241px) and (max-width: 2304px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 689px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 146px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 64px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }
}


@media screen and (min-width: 2305px) and (max-width: 2560px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 750px !important;
  }

  .why-choose-us-title {
    text-align: left !important;
    font-size: 166px !important;
    color: #ffffff !important;
    letter-spacing: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-p {
    text-align: left !important;
    font-size: 78px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
  }

  .why-choose-us-img {
    width: 100% !important;
    margin-top: -100px !important;
    margin-left: 100px !important;
    animation: swing 1s ease-in-out infinite alternate !important;
  }
}

@media screen and (min-width: 2561px) and (max-width: 3200px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 1055px !important;
}
  .why-choose-us-title {
    text-align: left !important;
    font-size: 201px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
}
  .why-choose-us-p {
    text-align: left !important;
    font-size: 100px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}
}



@media screen and (min-width: 3201px) and (max-width: 5000px) {
  .why-choose-us-container {
    background-color: #29336A !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
    margin-top: 100px !important;
    height: 1245px !important;
}
  .why-choose-us-title {
    text-align: left !important;
    font-size: 245px !important;
    color: #ffffff !important;
    letter-spacing: 5px !important;
    font-family: 'Libre Baskerville', serif !important;
}
  .why-choose-us-p {
    text-align: left !important;
    font-size: 122px !important;
    color: #ffffff !important;
    margin-top: 18px !important;
    margin-bottom: 0px !important;
    font-family: 'Libre Baskerville', serif !important;
}
}