.mission-container{
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 130px !important;
}
.mission-title{
    text-align: center;
    color: #316C2F;
    font-size: 70px !important;
    font-weight: 400 !important;
    font-family: 'Libre Baskerville', serif !important;  
}

.mission-description-p{
    text-align: center !important;
    font-size: 37px !important;
    padding-top: 0px !important;
    padding-left: 47px !important;
    padding-right: 47px !important;
    font-family: 'Libre Baskerville', serif !important;  
}


@media screen and (max-width: 325px) {
    .mission-container{
        padding-left: 16px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
    }
    .mission-title{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }

    .mission-description-p{
        text-align: center !important;
        font-size: 16px !important;

    }
}

@media screen and (min-width: 326px) and (max-width: 375px) {
    .mission-container{
        padding-left: 16px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
    }
    .mission-title{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }

    .mission-description-p{
        text-align: center !important;
        font-size: 16px !important;

    }   
    
}


@media screen and (min-width: 376px) and (max-width: 480px) {
    .mission-container{
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 0px !important;
    }
    .mission-title{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }

    .mission-description-p{
        text-align: center !important;
        font-size: 16px !important;
    }  
    
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .mission-container{
        padding-left: 16px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
    }
    .mission-title{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 50px !important ;
        font-weight: 400 !important;
    }

    .mission-description-p{
        text-align: center !important;
        font-size: 20px !important;

    }  
    
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }
    .mission-title{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 60px !important ;
        font-weight: 400 !important;
    }

    .mission-description-p{
        text-align: center !important;
        font-size: 25px !important;

    }  
    
}


@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important;
        font-weight: 400 !important;
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 28px !important;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1366px ) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important;
        font-weight: 400 !important;
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 28px !important;
    }
    
}

@media screen and (min-width: 1367px) and (max-width: 1440px ) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important;
        font-weight: 400 !important;
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 28px !important;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1920px ) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 70px !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important; 
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 37px !important;
        padding-top: 0px !important;
        padding-left: 47px !important;
        padding-right: 47px !important;
        font-family: 'Libre Baskerville', serif !important; 
    }
    
}


@media screen and (min-width: 1921px) and (max-width: 2560px ) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important;
        font-weight: 400 !important;
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 40px !important;
    }
    
}

@media screen and (min-width: 2561px) and (max-width: 3840px ) {
    .mission-container{
        padding-left: 16px !important;
      
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .mission-title {
        text-align: center !important;
        color: #316C2F !important;
        font-size: 200px !important;
        font-weight: 400 !important;
    }
    .mission-description-p {
        text-align: center !important;
        font-size: 80px !important;
    }
    
}
