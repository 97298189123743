.find-our-place {
    padding-left: 200px;
    padding-right: 200px;
}

.find-our-place-heading {
    font-size: 96px;
    color: #316C2F;
    font-weight: 400;
    font-family: 'Libre Baskerville', serif;
    margin-top: 50px;
    margin-bottom: 50px;
}

.find-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    margin-bottom: 100px;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    max-height: 520px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}


@media screen and (max-width: 325px) {
    .find-our-place {
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 0px !important;
    }

    .find-our-place-heading {
        font-size: 30px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 25px !important;
        margin-bottom: 0px !important;
    }
    .find-container {
        position: relative !important;
        width: 100% !important; 
        overflow: hidden !important;
        padding-top: 56.25% !important;
        margin-bottom: 130px !important;
    }
    

}

@media screen and (min-width: 325px) and (max-width: 375px) {
    .find-our-place {
        padding-left: 20px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }

    .find-our-place-heading {
        font-size: 30px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 25px !important;
        margin-bottom: 0px !important;
    }
    .find-container {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;
        padding-top: 56.25% !important;
        margin-bottom: 130px !important;
    }
    

}


@media screen and (min-width: 376px) and (max-width: 480px) {
    .find-our-place {
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 0px !important;
    }

    .find-our-place-heading {
        font-size: 30px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 25px !important;
        margin-bottom: 0px !important;
    }
    .find-container {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;
        padding-top: 56.25% !important;
        margin-bottom: 130px !important;
    }
    

}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .find-our-place {
        padding-left: 39px;
        padding-right: 42px;
        margin-top: -140px;
    }

    .find-our-place-heading {
        font-size: 61px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 145px !important;
        margin-bottom: 0px !important;
    }

    


}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .find-our-place {
        padding-left: 82px;
        padding-right: 87px;
    }

    .find-our-place-heading {
        font-size: 65px;
        color: #316C2F;
        font-weight: 400;
        font-family: 'Libre Baskerville', serif;
        margin-top: 44px;
        margin-bottom: 23px;
    }
}



@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .find-our-place {
        padding-left: 115px !important;
        padding-right: 115px !important;
    }

    .find-our-place-heading {
        font-size: 60px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 44px !important;
        margin-bottom: 23px !important;
    }

    .find-container {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;
        padding-top: 56.25% !important;
        margin-bottom: 140px !important ;
    }
}


@media screen and (min-width: 1281px) and (max-width: 1500px) {
    .find-our-place {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .find-our-place-heading {
        font-size: 70px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 44px !important;
        margin-bottom: 23px !important;
    }
    .find-container {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;
        padding-top: 56.25% !important;
        margin-bottom: 100px !important ;
    }
}



@media screen and (min-width: 1501px) and (max-width: 1920px) {
    .find-our-place {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .find-our-place-heading {
        font-size: 70px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 44px !important;
        margin-bottom: 23px !important;
    }
    .find-container {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;
        padding-top: 51.25% !important;
        margin-bottom: 0px !important ;
    }
}


@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .find-our-place {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .find-our-place-heading {
        font-size: 70px !important;
        color: #316C2F !important;
        font-weight: 400 !important;
        font-family: 'Libre Baskerville', serif !important;
        margin-top: 44px !important;
        margin-bottom: 23px !important;
    }
}