.begin-now-text{
    color: #316C2F !important;
    font-size: 70px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 500 !important;
    margin-top: 50px;
    text-align: center !important;
}

.begin-now-form{
    padding-left: 100px !important;
    padding-right: 100px !important;
    padding-bottom: 100px !important;
}

@media screen and (max-width: 500px) {
    .begin-now-text{
        font-size: 40px !important;
    }
    .begin-now-form {
        padding-left: 49px !important;
        padding-right: 30px !important;
        padding-bottom: 118px !important;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .begin-now-text{
        font-size: 45px !important;
    }
    .begin-now-form {
        padding-left: 49px !important;
        padding-right: 30px !important;
        padding-bottom: 118px !important;
    }
    
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
    .begin-now-text{
        font-size: 57px !important;
    }
    .begin-now-form {
        padding-left: 49px !important;
        padding-right: 30px !important;
        padding-bottom: 118px !important;
    }
    
}