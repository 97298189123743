
.product-carousel-container{
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 25px;
}
.product-img{
    width: 100%;
    object-fit: contain;
}

.product-carousel{
    background-color: #316C2F;
    border-radius: 30px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    gap: 10px;
}

.product-carousel:hover {
    transform: scale(1.1); 
}

@media screen and (max-width: 500px) {

    .product-carousel-container{
        padding-top: 30px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
    .product-img{
        width: 100% !important;
        object-fit: contain !important;
    }
    
    .product-carousel{
        background-color: #316C2F !important;
        border-radius: 30px !important;
        margin-left: 22px !important;
        margin-right: 10px !important;
        transition: transform 0.3s ease-in-out !important;
    }
    
    .product-carousel:hover {
        transform: scale(1.1) !important;  
    }
    
}

@media screen and (min-width:501px) and (max-width: 768px) {
    .product-carousel-container {
        padding-top: 50px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .product-carousel-container {
        padding-top: 50px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 3840px) {
    .product-carousel-container {
        padding-top: 50px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

