.vision-h4 {
    color: #316C2F !important;
    font-size: 70px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 500 !important;
}

.vision-container{
    padding-bottom: 100px !important;
}

.vision-p {
    font-size: 37px !important;
    font-family: 'Libre Baskerville', serif !important;
}



.vision-card {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 70% !important;
    border: 1px solid #316C2F !important;
    border-radius: 25px !important;

}

.vision-p-card {
    text-align: center !important;
    margin-top: 40px !important;
    font-size: 30px !important;
    font-family: Libre Baskerville, serif !important;
}

.vision-p-typo{
    font-size: 40px !important;
    font-family: Libre Baskerville, serif !important;
}

.vision-box{
    display: flex !important;
    justify-content: center !important;
    gap:100px !important; 
}


@media screen and (max-width: 600px) {
    .vision-h4 {
        color: #316C2F !important;
        font-size: 28px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
    }

    .vision-p {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .vision-p-typo {
        font-size: 27px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-p-card {
        text-align: center !important;
        margin-top: 20px !important;
        font-size: 21px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-box{
        display: block !important;
        margin-top: 25px !important;
    }
}


@media screen and (min-width: 600px) and (max-width: 768px) {
    
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .vision-h4 {
        color: #316C2F !important;
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
    }

    .vision-p {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .vision-box {
        display: flex !important;
        justify-content: center !important;
        gap: 100px !important;
        margin-top: 21px !important;
    }

    .vision-p-typo {
        font-size: 25px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-p-card {
        text-align: center !important;
        margin-top: 19px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
    }
}



@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .vision-h4 {
        color: #316C2F !important;
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
    }

    .vision-p {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .vision-box {
        display: flex !important;
        justify-content: center !important;
        gap: 100px !important;
        margin-top: 21px !important;
    }

    .vision-p-typo {
        font-size: 25px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-p-card {
        text-align: center !important;
        margin-top: 19px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
    }
}


@media screen and (min-width: 1281px) and (max-width: 1536px) {
    .vision-h4 {
        color: #316C2F !important;
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
    }

    .vision-p {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .vision-box {
        display: flex !important;
        justify-content: center !important;
        gap: 100px !important;
        margin-top: 21px !important;
    }

    .vision-p-typo {
        font-size: 25px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-p-card {
        text-align: center !important;
        margin-top: 19px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
    }
}

@media screen and (min-width: 1537px) and (max-width: 1800px) {
    .vision-h4 {
        color: #316C2F !important;
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
    }

    .vision-p {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .vision-box {
        display: flex !important;
        justify-content: center !important;
        gap: 100px !important;
        margin-top: 21px !important;
    }

    .vision-p-typo {
        font-size: 25px !important;
        font-family: Libre Baskerville, serif !important;
    }

    .vision-p-card {
        text-align: center !important;
        margin-top: 19px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
    }
}