.contact-card {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 70% !important;
    border: 1px solid #29336A !important;
    border-radius: 25px !important;
}

.contacts-container {
    justify-content: center !important;
    gap: 100px !important;
    margin-top: 100px !important;
}


.icon-div{
    display: flex !important;
    justify-content: center !important;
}

.icon {
    color: #316C2F !important;
    /* margin-left: 194px !important; */
    margin-top: 10px !important;
    font-size: 65px !important;
}

.contact-info {
    text-align: center !important;
    margin-top: 40px !important;
    font-size: 30px !important;
    font-family: Libre Baskerville, serif !important;
    color: #316C2F !important;
}

.contact-card-content {
    padding-bottom: 0px !important;
}

.contact-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .centered {
    text-align: center;
  }

  .contact-card-content {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Ensure the content takes the full height of the container */
  }
  

@media screen and (max-width: 320px) {
    .contacts-container {
        justify-content: center !important;
        gap: 0px !important;
        margin-top: 0px !important;
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 90px !important; */
        margin-top: 10px !important;
        font-size: 65px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 25px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {

        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-top: 40px !important;
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .contacts-container {
        justify-content: center !important;
        gap: 0px !important;
        margin-top: 0px !important;
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 115px !important; */
        margin-top: 10px !important;
        font-size: 65px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 25px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {

        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-top: 40px !important;
    }
}

@media screen and (min-width: 376px) and (max-width: 480px) {
    .contacts-container {
        justify-content: center !important;
        gap: 0px !important;
        margin-top: 8px !important;
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 130px !important; */
        margin-top: 10px !important;
        font-size: 65px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 25px !important;
        font-size: 20px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 55px !important;
        padding-right: 40px !important;
        padding-top: 40px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .contacts-container {
        justify-content: center !important;
        gap: 40px !important;
       margin-top: 8px !important; 
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 77px !important; */
        margin-top: 10px !important;
        font-size: 37px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 8px !important;
        font-size: 15px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 60% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .contacts-container {
        justify-content: center !important;
        gap: 40px !important;
       margin-top: 8px !important; 
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 105px !important; */
        margin-top: 10px !important;
        font-size: 37px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 5px !important;
        font-size: 18px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 60% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1280px) {

    .contacts-container {
        justify-content: center !important;
        gap: 25px !important;
       margin-top: 28px !important; 
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }
    .icon {
        color: #316C2F !important;
        margin-top: 10px !important;
        font-size: 60px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 39% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }

    .contact-card-content {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        height: 31% !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 40px !important;
        font-size: 17px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1500px) {
    .contacts-container {
        justify-content: center !important;
        gap: 40px !important;
       margin-top: 8px !important; 
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }

    .icon {
        color: #316C2F !important;
        /* margin-left: 150px !important; */
        margin-top: 10px !important;
        font-size: 60px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 5px !important;
        font-size: 25px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 60% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }
    
}

@media screen and (min-width: 1501px) and (max-width: 2560px) {
    .contacts-container {
        justify-content: center !important;
        gap: 40px !important;
       margin-top: 8px !important; 
    }
    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }
    .icon {
        color: #316C2F !important;
        margin-top: 10px !important;
        font-size: 100px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 5px !important;
        font-size: 27px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 60% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }
    
}

@media screen and (min-width: 2561px) and (max-width: 3840px) {
    .contacts-container {
        justify-content: center !important;
        gap: 40px !important;
       margin-top: 8px !important; 
    }

    .icon-div{
        display: flex !important;
        justify-content: center !important;
    }


    .icon {
        color: #316C2F !important;
        /* margin-left: 368px !important; */
        margin-top: 57px !important;
        font-size: 163px !important;
    }

    .contact-info {
        text-align: center !important;
        margin-top: 5px !important;
        font-size: 50px !important;
        font-family: Libre Baskerville, serif !important;
        color: #316C2F !important;
    }

    .custom-grid-contacts {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important;
    }
    .contact-card {
        background-color: transparent !important;
        box-shadow: none !important;
        height: 60% !important;
        border: 1px solid #29336A !important;
        border-radius: 25px !important;
    }
    
}