.explore-our-container-slider{
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
    position: relative;
}

.explore-h3{
    font-size: 90px !important;
    font-family: 'Libre Baskerville', serif !important; 
    font-weight: 400 !important;
    color: #316C2F !important;
    letter-spacing: 15px !important;
}

.explore-img-div-bg{
    position: absolute;
    top: 60px;
    right: 264px;
    width: 12%;
    height: 100%;
    z-index: -1;
}

.img-bg-explore{
    width: 100%;
    height: 100%;
    object-fit: contain; 
}

@media screen and (max-width: 500px) {
    .explore-our-container-slider{
        padding-top: 50px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: relative;
    }
    
    .explore-h3{
        font-size: 30px !important;
        font-family: 'Libre Baskerville', serif !important; 
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 0px !important;
    }
    
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .explore-our-container-slider{
        padding-top: 50px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: relative;
    }
    
    .explore-h3{
        font-size: 50px !important;
        font-family: 'Libre Baskerville', serif !important; 
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 0px !important;
    }
    
}


@media screen and (min-width: 768px) and (max-width: 1280px) {
    .explore-our-container-slider{
        padding-top: 50px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: relative;
    }
    
    .explore-h3{
        font-size: 45px !important;
        font-family: 'Libre Baskerville', serif !important; 
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 1px !important;
    }
    
}



@media screen and (min-width: 1280px) and (max-width: 1800px) {
    .explore-our-container-slider{
        padding-top: 50px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: relative;
    }
    
    .explore-h3{
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important; 
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 3px !important;
    }
    
}

@media screen and (min-width: 1801px) and (max-width: 2560px) {
    .explore-our-container-slider{
        padding-top: 50px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: relative;
    }
    
    .explore-h3{
        font-size: 80px !important;
        font-family: 'Libre Baskerville', serif !important; 
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 3px !important;
    }
    
}