.mui-social-icons{
    color: #fff !important;
    font-size: 175px !important;
    width: 70px !important;
    height: 70px !important;
}

.social-icons-div{
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: 16px !important;
}

.follow-title{
    color: #fff !important;
    margin-top: 10px !important;
    margin-left: 116px !important;
    font-family: 'Libre Baskerville', serif; 
}

/* @media screen and (max-width: 320px) {
    .mui-social-icons{
        color: #fff !important;
        font-size: 175px !important;
        width: 70px !important;
        height: 70px !important;
    }
    
    .social-icons-div{
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 0px !important;
    }
    
    .follow-title{
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 0px !important;
        font-family: 'Libre Baskerville', serif; 
        text-align: center !important;
    }
} */

@media screen and (max-width: 649px) {
    .social-icons-div{
        display: flex !important;
        justify-content: center !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 70px !important;
        width: 50px !important;
        height: 50px !important;
    }

    .follow-title{
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 0px !important;
        font-family: 'Libre Baskerville', serif; 
        text-align: center !important;
    }
}


@media screen and (min-width: 650px) and (max-width: 768px) {
    .social-icons-div{
      margin-left: 20px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 70px !important;
        width: 40px !important;
        height: 40px !important;
    }

    .follow-title{
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 78px !important;
        font-family: 'Libre Baskerville', serif; 
        text-align: left !important;
    }
}



@media screen and (min-width: 769px) and (max-width: 1024px) {
    .social-icons-div{
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 50px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 40px !important;
        height: 40px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 73px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .social-icons-div{
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 50px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 45px !important;
        height: 45px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 74px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
    .social-icons-div {
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 70px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 55px !important;
        height: 55px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 110px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}


@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .social-icons-div {
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 66px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 55px !important;
        height: 55px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 110px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}


@media screen and (min-width: 1441px) and (max-width: 1800px) {
    .social-icons-div {
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 66px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 55px !important;
        height: 55px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 110px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}


@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .social-icons-div {
        display: flex !important;
        justify-content: flex-start !important;
        margin-left: 65px !important;
    }

    .mui-social-icons{
        color: #fff !important;
        font-size: 60px !important;
        width: 55px !important;
        height: 55px !important;
    }

    .follow-title {
        color: #fff !important;
        margin-top: 10px !important;
        margin-left: 110px !important;
        font-family: 'Libre Baskerville', serif;
    }
    
}