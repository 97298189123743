.export-grid-container {
    padding-top: 147px !important;
    padding-bottom: 142px !important;
}

.export-grid-text {
    background-color: #29336A !important;
    height: 140px;
}

.flag {
    margin-top: -164px !important;
    margin-left: 60px !important;
    width: 15% !important;
    object-fit: contain !important;
}

.export-h3 {
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 400 !important;
    color: #fff !important;
    font-size: 59px !important;
    text-align: center !important;
    margin-top: -41px !important;
}

.verified {
    width: 70% !important;
    object-fit: none;
}

.verified-div {
    display: flex !important;
    margin-top: -55px !important;
    justify-content: flex-end !important;
    padding-right: 48px !important;
}


@media screen and (max-width: 325px) {
    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 22px !important;
        text-align: center !important;
        margin-top: 0px !important;
    }

    .flag {
        width: 30% !important;
        margin-top: -110px !important;
        margin-left: 30px !important;
    }

    .verified-div {
        display: flex !important;
        margin-top: -18px !important;
        justify-content: flex-end !important;
        padding-right: 21px !important;
        margin-left: 34px !important;
    }


    .verified {
        width: 100% !important;
        object-fit: contain;
    }

    .export-grid-container {
        padding-top: 147px !important;
        padding-bottom: 57px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 370px !important;
    }
}


@media screen and (min-width: 326px) and (max-width: 375px) {
    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: center !important;
        margin-top: 0px !important;
    }

    .flag {
        width: 30% !important;
        margin-top: -110px !important;
        margin-left: 30px !important;
    }

    .verified-div {
        display: flex !important;
        margin-top: -18px !important;
        justify-content: flex-end !important;
        padding-right: 21px !important;
        margin-left: 34px !important;
    }


    .verified {
        width: 100% !important;
        object-fit: contain;
    }

    .export-grid-container {
        padding-top: 147px !important;
        padding-bottom: 57px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 370px !important;
    }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: center !important;
        margin-top: 0px !important;
    }

    .flag {
        width: 30% !important;
        margin-top: -110px !important;
        margin-left: 30px !important;
    }

    .verified-div {
        display: flex !important;
        margin-top: -18px !important;
        justify-content: flex-end !important;
        padding-right: 21px !important;
        margin-left: 34px !important;
    }


    .verified {
        width: 100% !important;
        object-fit: contain;
    }

    .export-grid-container {
        padding-top: 147px !important;
        padding-bottom: 57px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 450px !important;
    }
}


@media screen and (min-width: 426px) and (max-width: 480px) {
    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: center !important;
        margin-top: 0px !important;
    }

    .flag {
        width: 30% !important;
        margin-top: -110px !important;
        margin-left: 30px !important;
    }

    .verified-div {
        display: flex !important;
        margin-top: -18px !important;
        justify-content: flex-end !important;
        padding-right: 21px !important;
        margin-left: 34px !important;
    }


    .verified {
        width: 100% !important;
        object-fit: contain;
    }

    .export-grid-container {
        padding-top: 147px !important;
        padding-bottom: 57px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 450px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 170px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -138px !important;
    }

    .export-grid-container {
        padding-top: 181px !important;
        padding-bottom: 310px !important;
    }

}


@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 170px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -138px !important;
    }
    .export-grid-container {
        padding-top: 181px !important;
        padding-bottom: 310px !important;
    }
}


@media screen and (min-width: 1281px) and (max-width: 1366px) {
    
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 250px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -138px !important;
    }
    .export-grid-container {
        padding-top: 181px !important;
        padding-bottom: 310px !important;
    }
}


@media screen and (min-width: 1367px) and (max-width: 1500px) {
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 250px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -200px !important;
    }
    .export-grid-container {
        padding-top: 210px !important;
        padding-bottom: 350px !important;
    }
}


@media screen and (min-width: 1501px) and (max-width: 1600px) {
   
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 250px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -200px !important;
    }
    .export-grid-container {
        padding-top: 210px !important;
        padding-bottom: 350px !important;
    }
}


@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 250px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -265px !important;
    }
    .export-grid-container {
        padding-top: 280px !important;
        padding-bottom: 380px !important;
    }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -42px !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
        margin-left: 46px;
    }

    .flag {
        margin-top: -130px !important;
        margin-left: 60px !important;
        width: 41% !important;
        object-fit: contain !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 250px !important;
    }

    .verified {
        width: 100% !important;
        object-fit: contain !important;
    }

    .verified-div {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
        margin-top: -265px !important;
    }
    .export-grid-container {
        padding-top: 280px !important;
        padding-bottom: 380px !important;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .export-grid-container {
        padding-top: 351px !important;
        padding-bottom: 300px !important;
    }
    .flag {
        margin-top: -137px !important;
        margin-left: 60px !important;
        width: 30% !important;
        object-fit: contain !important;
    }
    .verified-div {
        display: flex !important;
        margin-top: -285px !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
    }
    
    .verified {
        width: 100% !important;
        object-fit: none !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        margin-left: 53px;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .export-div{
        margin-top: 0px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 216px !important; 
    }
}


@media screen and (min-width: 1921px) and (max-width: 2048px) {
    .export-grid-container {
        padding-top: 351px !important;
        padding-bottom: 400px !important;
    }
    .flag {
        margin-top: -137px !important;
        margin-left: 60px !important;
        width: 30% !important;
        object-fit: contain !important;
    }
    .verified-div {
        display: flex !important;
        margin-top: -285px !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
    }
    
    .verified {
        width: 100% !important;
        object-fit: none !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        margin-left: 53px;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .export-div{
        margin-top: 0px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 216px !important; 
    }
}

@media screen and (min-width: 2049px) and (max-width: 2150px) {
    .export-grid-container {
        padding-top: 351px !important;
        padding-bottom: 400px !important;
    }
    .flag {
        margin-top: -137px !important;
        margin-left: 60px !important;
        width: 30% !important;
        object-fit: contain !important;
    }
    .verified-div {
        display: flex !important;
        margin-top: -285px !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
    }
    
    .verified {
        width: 100% !important;
        object-fit: none !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        margin-left: 53px;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .export-div{
        margin-top: 0px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 216px !important; 
    }
}


@media screen and (min-width: 2151px) and (max-width: 2360px) {
    .export-grid-container {
        padding-top: 351px !important;
        padding-bottom: 400px !important;
    }
    .flag {
        margin-top: -137px !important;
        margin-left: 60px !important;
        width: 30% !important;
        object-fit: contain !important;
    }
    .verified-div {
        display: flex !important;
        margin-top: -285px !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
    }
    
    .verified {
        width: 100% !important;
        object-fit: none !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        margin-left: 53px;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .export-div{
        margin-top: 0px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 216px !important; 
    }
}


@media screen and (min-width: 2361px) and (max-width: 2560px) {
    .export-grid-container {
        padding-top: 351px !important;
        padding-bottom: 400px !important;
    }
    .flag {
        margin-top: -137px !important;
        margin-left: 60px !important;
        width: 30% !important;
        object-fit: contain !important;
    }
    .verified-div {
        display: flex !important;
        margin-top: -285px !important;
        justify-content: flex-end !important;
        padding-right: 48px !important;
    }
    
    .verified {
        width: 100% !important;
        object-fit: none !important;
    }

    .export-h3 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #fff !important;
        margin-left: 53px;
        font-size: 25px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .export-div{
        margin-top: 0px !important;
    }

    .export-grid-text {
        background-color: #29336A !important;
        height: 216px !important; 
    }
}