.appbar {
    background-color: #29336A !important;
    height: 65px !important;
}

.appbar-logo-div {
    background-color: #fff;
    width: 152px;
    height: 65px !important;
    margin-left: -24px;
    border-top-right-radius: 170px;
    border-bottom-right-radius: 170px;
}

.appbar-logo {
    width: 100%;
    height: 10vh;
    object-fit: contain;
    margin-left: -17px;
    margin-top: 5px;
    margin-bottom: 17px;
}

.appbar-nav-div {
    margin-left: 400px;
    display: flex !important;
    gap: 60px !important;
}

.appbar-nav {
    color: #DDA15E !important;
    font-size: 25px !important;
    font-family: 'Libre Baskerville', serif !important;
    word-spacing: 3px !important;
    cursor: pointer;
    text-decoration: none;
}

.drawer-content {
    padding: 20px;
    background-color: #29336A !important;
}

.drawer-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.drawer-content ul li a {
    font-size: 24px;
    letter-spacing: 2px;
    color: #DDA15E !important;
    text-decoration: none;
    cursor: pointer;
    line-height: 65px;
    font-family: 'Libre Baskerville', serif !important;
}

.drawer-content ul li a:hover {
    color: #DDA15E !important;
    text-decoration: none;
    font-family: 'Libre Baskerville', serif !important;
}




@media screen and (max-width: 325px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 138px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    
    .appbar-toolbar {
        gap:152px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 10px !important;
    }
}

@media screen and (min-width: 326px) and (max-width: 350px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 145px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 10px !important;
    }

    
    .appbar-toolbar {
        gap:153px !important;
    }
}

@media screen and (min-width: 351px) and (max-width:375px) {
   
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 142px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 35px !important;
    }

    .appbar-toolbar {
        gap:180px !important;
    }
}

@media screen and (min-width: 376px) and (max-width:400px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 142px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 60px !important;
    }
    .appbar-toolbar {
        gap: 205px !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 430px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 142px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 60px !important;
    }

    .appbar-toolbar {
        gap: 225px !important;
    }
}


@media screen and (min-width: 431px) and (max-width: 450px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 142px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height:7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 70px !important;
    }
    .appbar-toolbar {
        gap: 258px !important;
    }
}


@media screen and (min-width: 451px) and (max-width: 500px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 36px !important;
    }

    .appbar-nav-Box {
        margin-left: 21px !important;
    }

    .appbar-logo-div {
        background-color: #fff !important;
        width: 117px !important;
        margin-left: -20px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 7vh !important;
        object-fit: contain !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 17px !important;
    }

    .export-house-appbar{
        width: 100px !important;
        margin-left: 101px !important;
    }
    .appbar-toolbar {
        gap: 305px !important;
    }
}


@media screen and (min-width: 501px) and (max-width: 600px) {
    .menu-icon {
        margin-left: 0px !important;
        font-size: 40px !important;
    }

    .appbar-toolbar {
        gap: 305px !important;
    }

}

@media screen and (min-width: 601px) and (max-width: 768px) {
    .menu-icon {
        margin-left: 210px !important;
        font-size: 40px !important;
    }

}

@media screen and (min-width: 769px) and (max-width: 970px) {
    .menu-icon {
        margin-left: 210px !important;
        font-size: 40px !important;
    }

}


@media screen and (min-width: 971px) and (max-width: 1025px) {

    .appbar-logo-div {
        background-color: #fff;
        width: 215px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 90px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 107px !important;
        display: flex !important;
        gap: 30px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer !important;
        text-decoration: none !important;
    }

    .appbar {
        background-color: #29336A !important;
        height: 88px !important;
    }

}

@media screen and (min-width: 1026px) and (max-width: 1100px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 200px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 60px !important;
        display: flex !important;
        gap: 45px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }
}


@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 200px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 60px !important;
        display: flex !important;
        gap: 57px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 230px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 90px !important;
        display: flex !important;
        gap: 65px !important
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }

}


@media screen and (min-width: 1301px) and (max-width: 1440px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 230px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 90px !important;
        display: flex !important;
        gap: 65px !important
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }

}

@media screen and (min-width: 1441px) and (max-width: 1500px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 230px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 130px !important;
        display: flex !important;
        gap: 92px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }
}


@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 230px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 190px !important;
        display: flex !important;
        gap: 92px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }

}

@media screen and (min-width: 1601px) and (max-width: 1700px) {

    .appbar-logo-div {
        background-color: #fff !important;
        width: 230px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 190px !important;
        display: flex !important;
        gap: 92px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }

}


@media screen and (min-width: 1701px) and (max-width: 1800px) {

    .appbar-logo-div {
        background-color: #fff !important;
        width: 250px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 90px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 245px !important;
        display: flex !important;
        gap: 76px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }
}


@media screen and (min-width: 1801px) and (max-width: 2000px) {

    .appbar-logo-div {
        background-color: #fff !important;
        width: 250px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 85px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 9vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 260px !important;
        display: flex !important;
        gap: 102px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 85px !important;
    }
}

@media screen and (min-width: 2001px) and (max-width: 2100px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 250px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
        height: 105px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .appbar-nav-div {
        margin-left: 260px !important;
        display: flex !important;
        gap: 102px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

    .appbar {
        background-color: #29336A !important;
        height: 103px !important;
    }

}



@media screen and (min-width: 2101px) and (max-width: 2560px) {
    .appbar-logo-div {
        background-color: #fff !important;
        width: 260px !important;
        margin-left: -24px !important;
        border-top-right-radius: 170px !important;
        border-bottom-right-radius: 170px !important;
    }

    .appbar-logo {
        width: 100% !important;
        height: 10vh !important;
        object-fit: contain !important;
        margin-left: -17px !important;
        margin-top: 5px !important;
        margin-bottom: 17px !important;
    }

    .appbar-nav-div {
        margin-left: 430px !important;
        display: flex !important;
        gap: 44px !important;
    }

    .appbar-nav {
        color: #DDA15E !important;
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        word-spacing: 3px !important;
        cursor: pointer;
        text-decoration: none;
    }

}