.quality {
  overflow: hidden;
  margin-top: 19px;
}

.quality-h4 {
  text-align: center;
  font-family: 'Libre Baskerville', serif;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.quality-image {
  width: 70% !important;
  margin-left: 60px !important;
  animation: rotate 5s linear infinite;
}

.quality-image-1 {
  width: 70% !important;
  margin-left: 60px !important;
}


@media screen and (max-width: 325px) {

  .quality {
    overflow: hidden;
    margin-top: 0px !important;
  }

  .quality-image-text {
    display: flex !important;
    justify-content: space-around !important;
    gap: 20px !important;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
    padding: 0px !important;
  }


  .quality-h4 {
    font-family: 'Libre Baskerville', serif;
    font-size: 13px !important;
    margin-left: 37px !important;
  }

  .quality-image-container {
    margin-right: 53px;
  }

  .quality-image-1 {
    width: 70% !important;
    margin-left: 39px !important;
  }

}

@media screen and (min-width: 326px) and (max-width: 375px) {
  .quality {
    overflow: hidden;
    margin-top: 50px;
  }


  .quality-h4 {
    font-family: 'Libre Baskerville', serif;
    font-size: 13px;
    margin-left: 68px;
  }

  .quality-image-container {
    margin-right: 53px;
  }

  .quality-image {
    width: 90% !important;
    margin-left: 30px !important;
    animation: rotate 5s linear infinite !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 480px) {
  .quality {
    overflow: hidden;
    margin-top: 0px !important;
  }

  .quality-h4 {
    font-family: 'Libre Baskerville', serif;
    font-size: 16px;
    margin-left: 37px;
  }

  .quality-image-container {
    margin-right: 53px;
  }

  .quality-image {
    width: 90% !important;
    margin-left: 30px !important;
    animation: rotate 5s linear infinite !important;
  }

  .quality-image-1 {
    width: 70% !important;
    margin-left: 45px !important;
  }

  .quality-image-text {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 0px;
  }

}




@media screen and (min-width: 481px) and (max-width: 768px) {
  .quality {
    overflow: hidden;
    margin-top: 60px !important;
  }

  .quality-h4 {
    font-family: 'Libre Baskerville', serif;
    font-size: 18px;
    margin-left: 68px;
  }

  .quality-image {
    width: 50% !important;
    margin-left: 110px !important;
  }

  .quality-image-container {
    margin-right: 0px !important;
  }

  .quality-image {
    width: 90% !important;
    margin-left: 54px !important;
    animation: rotate 5s linear infinite !important;
  }

  .quality-image-text {
    display: flex !important;
    justify-content: space-around !important;
    gap: 20px !important;
    margin-top: 0px !important;
    margin-bottom: 50px !important;
    padding: 0px !important;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .quality {
    overflow: hidden !important;
    margin-top: 24px !important;
    padding-left: 135px !important;
  }

  .quality-image {
    width: 45% !important;
    margin-left: 60px !important;
    animation: rotate 5s linear infinite !important;
  }

  .quality-h4 {
    margin-left: -135px !important;
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .quality {
    overflow: hidden !important;
    margin-top: 24px !important;
    padding-left: 135px !important;
  }

  .quality-image {
    width: 45% !important;
    margin-left: 60px !important;
    animation: rotate 5s linear infinite !important;
  }

  .quality-h4 {
    margin-left: -135px !important;
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .quality {
    overflow: hidden !important;
    margin-top: 0px !important;
  }

  .quality-h4 {
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
    margin-left: 60px !important;
  }
  .quality-h4 {
    font-size: 18px !important;
    margin-left: 30px !important;
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
}

.quality-image {
  width: 60% !important;
  margin-left: 80px !important;
  animation: rotate 5s linear infinite !important;
}
}


@media screen and (min-width: 1441px) and (max-width: 1800px) {
  .quality {
    overflow: hidden !important;
    margin-top: 0px !important;
  }

  .quality-h4 {
    font-size: 20px !important;
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
    margin-left: 0px !important;
  }

  .quality-image {
    width: 60% !important;
    margin-left: 80px !important;
    animation: rotate 5s linear infinite !important;
  }

}

@media screen and (min-width: 1801px) and (max-width: 2560px) {
  .quality-h4 {
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
    font-size: 26px !important;
  }
  .quality-image {
    width: 60% !important;
    margin-left: 80px !important;
    animation: rotate 5s linear infinite !important;
  }

}

@media screen and (min-width: 2561px) and (max-width: 3840px) {
  .quality-h4 {
    text-align: center !important;
    font-family: 'Libre Baskerville', serif !important;
    font-size: 90px !important;
  }

}