.heritage-text-container {
    padding-top: 100px;
}

.heritage-text {
    font-size: 35px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 600 !important;
    letter-spacing: 5px !important;
    color: #316C2F !important;
}

.heritage-text-sub {
    font-size: 75px !important;
    font-family: 'Libre Baskerville', serif !important;
    color: #316C2F !important;
}

@media screen and (max-width: 325px) {
    .heritage-text-container {
        padding-top: 50px;
    }

    .heritage-text {
        font-size: 13px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 326px) and (max-width: 500px) {
    .heritage-text-container {
        padding-top: 50px;
    }

    .heritage-text {
        font-size: 18px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .heritage-text-container {
        padding-top: 65px;
    }

    .heritage-text {
        font-size: 30px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 8px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 51px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;

    }

}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 34px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 44px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}


@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 34px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 45px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1800px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 34px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 50px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }
}


@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 33px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 3px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}


@media screen and (min-width: 1921px) and (max-width: 2048px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 5px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 150px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 2049px) and (max-width: 2560px) {
    .heritage-text-container {
        padding-top: 70px;
    }

    .heritage-text {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 5px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 130px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 2561px) and (max-width: 3200px) {
    .heritage-text-container {
        padding-top: 100px;
    }

    .heritage-text {
        font-size: 140px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 70px !important;
        color: #316C2F !important;
    }

    .heritage-text-sub {
        font-size: 75px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}

@media screen and (min-width: 3201px) and (max-width: 5000px) {
    .heritage-text-container {
        padding-top: 100px;
    }

    .heritage-text {
        font-size: 150px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        letter-spacing: 55px !important;
        color: #316C2F !important;
        line-height: 70px;
    }

    .heritage-text-sub {
        font-size: 75px !important;
        font-family: 'Libre Baskerville', serif !important;
        color: #316C2F !important;
    }

}