.product-card-div{
    margin-top: 100px;
}

.product-card-heading{
    color: #316C2F !important;
    font-family: 'Libre Baskerville', serif !important; 
    font-weight: 600 !important;
}

.card-text{
    font-family: 'Libre Baskerville', serif !important; 
}

.custom-grid-item {
    background-color: #316C2F !important;
    border-radius: 100px !important;
    width: 385px !important;
    height: 484px !important;
    margin-bottom: 30px !important;
}

.card-image {
    width: 359px;
    height: 374px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-left: 14px;
    margin-top: 12px;
    border-top-left-radius: 88px;
    border-top-right-radius: 88px;
}

.custom-container {
    padding-left: 240px !important;
    padding-right: 150px !important;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.card-image-img {
    margin-top: 10px !important;
    object-fit: contain !important;
    margin-bottom: 50px !important;
}


@media screen and (max-width: 320px) {
    .custom-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 239px !important;
        height: 400px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 213px;
        height: 300px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 14px;
        margin-top: 12px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
    .card-image-img {
        width: 180px !important;
        height: 353px !important;
        margin-top: -16px !important;
        margin-bottom: 30px !important;
        object-fit: contain !important;
    }
}


@media screen and (min-width: 320px) and (max-width: 375px) {
    .custom-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 315px !important;
        height: 450px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 290px;
        height: 350px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 13px;
        margin-top: 13px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
    .custom-container {
        padding-left: 36px !important;
        padding-right: 45px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 315px !important;
        height: 450px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 290px;
        height: 350px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 13px;
        margin-top: 13px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
    .custom-container {
        padding-left: 60px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 315px !important;
        height: 450px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 290px;
        height: 350px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 13px;
        margin-top: 13px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
        .custom-container {
            padding-left: 60px !important;
            padding-right: 36px !important;
            margin-top: 50px !important;
        }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .custom-container {
        padding-left: 60px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 300px !important;
        height: 484px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 268px;
        height: 374px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 16px;
        margin-top: 16px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }

    .card-image-img {
        width: 214px !important;
        height: 353px !important;
        margin-top: 10px !important;
        object-fit: contain !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .custom-container {
        padding-left: 100px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }
    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 98px !important;
        width: 272px !important;
        height: 385px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 244px !important;
        height: 284px !important;
        background-color: #fff !important;
        display: flex !important;
        justify-content: center !important;
        margin-left: 14px !important;
        margin-top: 14px !important;
        border-top-left-radius: 88px !important;
        border-top-right-radius: 88px !important;
    }
    .card-image-img {
        margin-top: 10px !important;
        object-fit: contain !important;
        margin-bottom: 50px !important;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
    .custom-container {
        padding-left: 85px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 326px !important;
        height: 436px !important;
        margin-bottom: 30px !important;
    }
    .card-image {
        width: 300px !important;
        height: 336px !important;
        background-color: #fff !important;
        display: flex !important;
        justify-content: center !important;
        margin-left: 14px !important;
        margin-top: 12px !important;
        border-top-left-radius: 88px !important;
        border-top-right-radius: 88px !important; 
    }
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .custom-container {
        padding-left: 60px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .custom-container {
        padding-left: 160px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .custom-container {
        padding-left: 260px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 600px !important;
        height: 700px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 571px;
        height: 595px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 14px;
        margin-top: 12px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
}

@media screen and (min-width: 2561px) and (max-width: 3840px) {
    .custom-container {
        padding-left: 260px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }

    .custom-grid-item {
        background-color: #316C2F !important;
        border-radius: 100px !important;
        width: 923px !important;
        height: 1000px !important;
        margin-bottom: 30px !important;
    }

    .card-image {
        width: 893px;
        height: 886px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        margin-left: 14px;
        margin-top: 12px;
        border-top-left-radius: 88px;
        border-top-right-radius: 88px;
    }
}

@media screen and (min-width: 1441px) and (max-width: 2304px) {
    .custom-container {
        padding-left: 135px !important;
        padding-right: 36px !important;
        margin-top: 50px !important;
    }
}