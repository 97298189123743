/* File: StickySocialMedia.css */

.sticky-social-icons {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1000;
  }
  
  .sticky-social-icons a {
    display: block;
    padding: 10px;
    /* background-color: #333; */
    color: white;
    text-decoration: none;
    text-align: center;
    /* border: 1px solid #444; */
    margin-bottom: 5px;
    transition: background-color 0.3s;
  }
   