.testimonials-container-grid {
    padding-left: 65px !important;
    padding-right: 100px !important;
    padding-top: 50px;
    padding-bottom: 200px;
}

.testimonials-h4 {
    font-size: 48px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    color: #316C2F;
    letter-spacing: 15px;
}

.testimonials-subtitle {
    font-size: 110px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    color: #316C2F;
}

@media screen and (max-width: 500px) {
    .testimonials-container-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 50px !important;
    }

    .testimonials-h4 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 2px !important;
    }

    .testimonials-subtitle {
        font-size: 26px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 1280px) {
    .testimonials-container-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 50px !important;
    }

    .testimonials-h4 {
        font-size: 30px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 0px !important;
    }

    .testimonials-subtitle {
        font-size: 45px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
    
}


@media screen and (min-width: 1280px) and (max-width: 1440px) {
    .testimonials-container-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 50px !important;
    }

    .testimonials-h4 {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 0px !important;
    }

    .testimonials-subtitle {
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1800px) {
    .testimonials-h4 {
        font-size: 35px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 0px !important;
    }

    .testimonials-subtitle {
        font-size: 70px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
  }


@media screen and (min-width: 1801px) and (max-width: 2560px) {
    .testimonials-container-grid {
        padding-left: 100px !important;
        padding-right: 100px !important;
        padding-top: 50px !important;
    }

    .testimonials-h4 {
        font-size: 40px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 20px !important;
    }

    .testimonials-subtitle {
        font-size: 70px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
    
}


@media screen and (min-width: 2560px) and (max-width: 5000px) {
    .testimonials-container-grid {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 50px !important;
    }

    .testimonials-h4 {
        font-size: 90px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        letter-spacing: 40px !important;
    }

    .testimonials-subtitle {
        font-size: 170px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        color: #316C2F !important;
    }
    
}