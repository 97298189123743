.your-brand-container{
    background-color:#29336A !important;
    height: 100px !important;
    margin-bottom: 50px !important;
}

.your-brand-text{
    color: #fff !important;
    font-size: 40px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 500 !important;
    margin-top: 25px !important;
    text-align: center !important;
}

@media screen and (max-width: 500px) {
    .your-brand-text{
        font-size: 20px !important;
    }
    
}


@media screen and (min-width: 501px) and (max-width: 768px) {
    .your-brand-text{
        font-size: 25px !important;
    }
    
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
    .your-brand-text{
        font-size: 30px !important;
    }
    
}