.our-process-container {
    padding-top: 50px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.our-process-h4 {
    color: #316C2F !important;
    font-size: 70px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.our-process-img-grid-div {
    display: flex !important;
    margin-left: 10px !important;
}

.our-process-h5 {
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 600 !important;
}

.our-process-img {
    width: 85% !important;
}

.our-process-icon {
    font-size: 38px !important;
}


@media screen and (max-width:325px) {
    .our-process-h4 {
        font-size: 40px !important;
    }
    
.our-process-img-grid-div {
    display: block !important;
    margin-left: 10px !important;
}

.our-process-container {
    padding-top: 50px !important;
    padding-left: 51px !important;
    padding-right: 30px !important;
}

.our-process-icon-div {
    display: flex;
    justify-content: center;
    transform: rotateZ(90deg);
    margin-left: -44px !important;
}
.our-process-h5 {
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 600 !important;
    margin-left: -44px !important;
}
}

@media screen and (min-width:326px) and (max-width:500px) {
    .our-process-h4 {
        font-size: 40px !important;
    }
    
.our-process-img-grid-div {
    display: block !important;
    margin-left: 10px !important;
}

.our-process-container {
    padding-top: 50px !important;
    padding-left: 51px !important;
    padding-right: 30px !important;
}

.our-process-icon-div {
    display: flex;
    justify-content: center;
    transform: rotateZ(90deg);
    margin-left: -44px !important;
}
.our-process-h5 {
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 600 !important;
    margin-left: -44px !important;
}
}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .our-process-h4 {
        font-size: 40px !important;
    }
    .our-process-h4 {
        color: #316C2F !important;
        font-size: 40px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
        text-align: center !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .our-process-icon {
        font-size: 23px !important;
    }
    
    .our-process-h4 {
        color: #316C2F !important;
        font-size: 57px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
        text-align: center !important;
    }
    .our-process-h5 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        font-size: 20px !important;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .our-process-icon {
        font-size: 23px !important;
    }
    
    .our-process-h4 {
        color: #316C2F !important;
        font-size: 57px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
        text-align: center !important;
    }
    .our-process-h5 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        font-size: 20px !important;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .our-process-icon {
        font-size: 25px !important;
    }
    
    .our-process-h4 {
        color: #316C2F !important;
        font-size: 57px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
        text-align: center !important;
    }
    .our-process-h5 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        font-size: 20px !important;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .our-process-icon {
        font-size: 28px !important;
    }
    
    .our-process-h4 {
        color: #316C2F !important;
        font-size: 60px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 500 !important;
        text-align: center !important;
    }
    .our-process-h5 {
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 600 !important;
        font-size: 20px !important;
    }
}