.why-choose-us-title-private-mobile {
    font-size: 20px !important;
    text-align: center !important;
    color: #316C2F !important;
    font-family: 'Libre Baskerville', serif !important;
}

.why-choose-us-title-private-mobile-main {
    color: #316C2F !important;
    font-size: 40px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 500 !important;
    margin-top: 50px !important;
}

.why-choose-us-left-div-ul-mobile {
    display: flex !important;
    justify-content: center !important;
    color: #000000 !important;
    font-weight: 600 !important;
    font-family: 'Libre Baskerville', serif !important;
    font-size: 12px !important;
}

.why-choose-us-img-private-1-mobile,
.why-choose-us-img-private-2-mobile,
.why-choose-us-img-private-3-mobile,
.why-choose-us-img-private-4-mobile {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}