 .footer-container {
    background-color: #29336A !important;
    border-top-left-radius: 60px;
    border-top-right-radius: 50px;
}

.QuickLinks-Contacts{
    display: flex;
}


@media screen and (max-width: 500px) {
    .QuickLinks-Contacts{
        display: block !important;
    }
}

@media screen and (min-width:501px) and (max-width: 768px) {
    .QuickLinks-Contacts{
        display: flex !important;
    }
}