.our-pacakging-container{
    padding-top: 50px !important;
}

.our-pacakging-h4{
    color: #316C2F !important;
    font-size: 70px !important;
    font-family: 'Libre Baskerville', serif !important;
     font-weight: 500 !important;
     text-align: center !important;
}

.our-pacakging-grid-text{
    display: flex !important;
    justify-content: center !important;
}

.our-pacakging-img{
 width: 100%; 
 object-fit: contain !important;
}

 @media screen and (max-width: 500px) {
    .our-pacakging-h4{
        font-size: 40px !important;
    }
 }

 @media screen and (min-width: 501px) and (max-width: 768px) {
    .our-pacakging-h4{
        font-size: 45px !important;
    }
    
 }

 @media screen and (min-width: 769px) and (max-width: 1400px) {
    .our-pacakging-h4{
        font-size: 57px !important;
    }
    
 }

 @media screen and (min-width: 1401px) and (max-width: 1600px) {
    .our-pacakging-h4{
        font-size: 60px !important;
    }
    
 }