.product-process-container{
    margin-top: 100px !important;
}
.process-img{
    width: 80% !important;
    object-fit: contain !important;
    margin-left: 170px !important;
}

.our-process-heading{
    text-align: center !important;
    letter-spacing: 7px !important;
    font-size: 70px !important;
    font-weight: 400 !important;
    font-family: 'Libre Baskerville', serif !important;
    color: #316C2F !important;
}

@media screen and (max-width: 325px) {
    .our-process-heading {
        text-align: center !important;
        letter-spacing: 1px !important;
        font-size: 39px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .process-img {
        width: 80% !important;
        object-fit: contain !important;
        margin-left: 40px !important;
    }
    

    .product-process-container{
        margin-top: 30px !important;
    }

    .our-process-heading{
        text-align: center !important;
        letter-spacing: 0px !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }
}

@media screen and (min-width: 326px) and (max-width: 375px) {
    .our-process-heading {
        text-align: center !important;
        letter-spacing: 1px !important;
        font-size: 39px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .product-process-container{
        margin-top: 30px !important;
    }
}


@media screen and (min-width: 376px) and (max-width: 430px) {
    .our-process-heading {
        text-align: center !important;
        letter-spacing: 1px !important;
        font-size: 39px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .product-process-container{
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 431px) and (max-width: 768px) {
    .product-process-container{
        margin-top: 30px !important;
    }
    .our-process-heading {
        text-align: center !important;
        letter-spacing: 7px !important;
        font-size: 47px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }

    .process-img {
        width: 90% !important;
        object-fit: contain !important;
        margin-left: 44px !important;
    }
    
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .product-process-container{
        margin-top: 30px !important;
    }
    .our-process-heading {
        text-align: center !important;
        letter-spacing: 4px !important;
        font-size: 47px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }
    
    .process-img {
        width: 80% !important;
        object-fit: contain !important;
        margin-left: 122px !important;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .product-process-container{
        margin-top: 50px !important;
    }
    .process-img{
        width: 80% !important;
        object-fit: contain !important;
        margin-left: 170px !important;
    }
    
    .our-process-heading{
        text-align: center !important;
        letter-spacing: 7px !important;
        font-size: 70px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
    .product-process-container{
        margin-top: 50px !important;
    }
    .process-img{
        width: 80% !important;
        object-fit: contain !important;
        margin-left: 265    px !important;
    }
    
    .our-process-heading{
        text-align: center !important;
        letter-spacing: 7px !important;
        font-size: 122px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }
    
}

 @media screen and (min-width: 2561px) and (max-width: 3840px) {
    .product-process-container{
        margin-top: 50px !important;
    }
    .process-img{
        width: 90% !important;
        object-fit: contain !important;
        margin-left: 265px !important;
    }
    
    .our-process-heading{
        text-align: center !important;
        letter-spacing: 7px !important;
        font-size: 315px !important;
        font-weight: 400 !important;
        color: #316C2F !important;
        font-family: 'Libre Baskerville', serif !important;
    }
    
 }