.infrastructure-img-text {
    margin-top: 100px;
    margin-left: 100px;
}

.infrastructure-img-1 {
    width: 90%;
    object-fit: contain;
}

.infrastructure-text-container-1 {
    margin-top: 100px;
    margin-left: -70px;
}

.infrastructure-text-1 {
    font-size: 25px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 400 !important;
    line-height: 54px !important;
    text-align: left !important;
    margin-left: 40px !important;
    word-spacing: 0px !important;
}

.infrastructure-text-2 {
    font-size: 25px !important;
    font-family: 'Libre Baskerville', serif !important;
    font-weight: 400 !important;
    line-height: 54px !important;
    text-align: left !important;
    margin-left: 105px !important;
    word-spacing: 0px !important;
    margin-top: 50px;
}


.infrastructure-span {
    color: #29336A;
    font-size: 60px;
}

.infrastructure-img-2 {
    width: 90%;
    object-fit: contain;
    margin-left: 100px !important;
}

.infrastructure-img-3 {
    width: 90% !important;
    object-fit: contain;
    margin-left: 112px;
    margin-top: 44px;
}

.infrastructure-img-4 {
    width: 90% !important;
    object-fit: contain;
    margin-top: 21px;
    margin-left: 165px;
}


@media screen and (max-width: 325px) {
    .infrastructure-img-text {
        margin-top: 35px !important;
        margin-left: 15px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 31px;
        margin-left: -64px;
    }

    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    

    .infrastructure-img-2 {
        width: 79%;
        object-fit: contain;
        margin-left: 112px !important;
    }

    .infrastructure-img-3 {
        width: 79% !important;
        object-fit: contain !important;
        margin-left: 50px !important;
        margin-top: 215px !important;
    }

    .infrastructure-text-container-2 {
        margin-top: 100px !important;
        margin-left: 100px !important;
    }


    .infrastructure-img-text-1 {
        margin-top: -115px;
        margin-left: 0px !important;
    }

    .infrastructure-img-4 {
        width: 79% !important;
        object-fit: contain;
        margin-top: -665px;
        margin-left: 137px;
    }

    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 22px !important;
        word-spacing: 0px !important;
        width: 87%;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 30px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 31px !important;
        word-spacing: 0px !important;
        width: 87%;
        margin-top: 200px !important;
    }


}


@media screen and (min-width: 326px) and (max-width: 375px) {
  
    .infrastructure-img-text {
        margin-top: 35px !important;
        margin-left: 15px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 31px;
        margin-left: -64px;
    }

    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    

    .infrastructure-img-2 {
        width: 79%;
        object-fit: contain;
        margin-left: 112px !important;
    }

    .infrastructure-img-3 {
        width: 79% !important;
        object-fit: contain !important;
        margin-left: 50px !important;
        margin-top: 215px !important;
    }

    .infrastructure-text-container-2 {
        margin-top: 100px !important;
        margin-left: 100px !important;
    }


    .infrastructure-img-text-1 {
        margin-top: -115px;
        margin-left: 0px !important;
    }

    .infrastructure-img-4 {
        width: 79% !important;
        object-fit: contain;
        margin-top: -665px;
        margin-left: 137px;
    }

    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 22px !important;
        word-spacing: 0px !important;
        width: 87%;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 30px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 31px !important;
        word-spacing: 0px !important;
        width: 87%;
        margin-top: 200px !important;
    }

}


@media screen and (min-width: 376px) and (max-width: 425px) {
   
    .infrastructure-img-text {
        margin-top: 35px !important;
        margin-left: 15px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 31px;
        margin-left: -64px;
    }

    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    

    .infrastructure-img-2 {
        width: 79%;
        object-fit: contain;
        margin-left: 112px !important;
    }

    .infrastructure-img-3 {
        width: 79% !important;
        object-fit: contain !important;
        margin-left: 50px !important;
        margin-top: 215px !important;
    }

    .infrastructure-text-container-2 {
        margin-top: 100px !important;
        margin-left: 100px !important;
    }


    .infrastructure-img-text-1 {
        margin-top: -115px;
        margin-left: 0px !important;
    }

    .infrastructure-img-4 {
        width: 79% !important;
        object-fit: contain;
        margin-top: -665px;
        margin-left: 137px;
    }

    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 22px !important;
        word-spacing: 0px !important;
        width: 87%;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 30px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 31px !important;
        word-spacing: 0px !important;
        width: 87%;
        margin-top: 200px !important;
    }


}


@media screen and (min-width: 426px) and (max-width: 480px) {
   
    .infrastructure-img-text {
        margin-top: 35px !important;
        margin-left: 15px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 31px;
        margin-left: -64px;
    }

    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    

    .infrastructure-img-2 {
        width: 79%;
        object-fit: contain;
        margin-left: 112px !important;
    }

    .infrastructure-img-3 {
        width: 79% !important;
        object-fit: contain !important;
        margin-left: 50px !important;
        margin-top: 215px !important;
    }

    .infrastructure-text-container-2 {
        margin-top: 100px !important;
        margin-left: 100px !important;
    }


    .infrastructure-img-text-1 {
        margin-top: -115px;
        margin-left: 0px !important;
    }

    .infrastructure-img-4 {
        width: 79% !important;
        object-fit: contain;
        margin-top: -665px;
        margin-left: 137px;
    }

    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 22px !important;
        word-spacing: 0px !important;
        width: 87%;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 30px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 31px !important;
        word-spacing: 0px !important;
        width: 87%;
        margin-top: 200px !important;
    }


}


@media screen and (min-width: 481px) and (max-width: 768px) {
    .infrastructure-img-text {
        margin-top: 100px;
        margin-left: 100px;
    }

    .infrastructure-img-1 {
        width: 90%;
        object-fit: contain;
    }

    .infrastructure-text-container-1 {
        margin-top: 47px;
        margin-left: 14px;
    }

    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 40px !important;
        word-spacing: 0px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 38px;
    }

    .infrastructure-img-2 {
        width: 80%;
        object-fit: contain;
    
    }

    .infrastructure-img-3 {
        width: 80% !important;
        object-fit: contain;
        margin-left: 112px;
        margin-top: 515px;
    }

    .infrastructure-img-4 {
        width: 80% !important;
        object-fit: contain;
        margin-top: -1365px;
        margin-left: 130px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .infrastructure-img-text {
        margin-top: 60px;
        margin-left: 60px;
    }

    .infrastructure-img-1 {
        width: 90%;
        object-fit: contain;
    }

    .infrastructure-img-2 {
        width: 90%;
        object-fit: contain;
        margin-left: 67px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 55px !important;
        margin-left: -70px !important;
    }

    .infrastructure-img-3 {
        width: 90% !important;
        object-fit: contain;
        margin-left: 63px !important;
        margin-top: 44px !important;
    }

    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain;
        margin-top: -90px !important;
        margin-left: 115px !important;
    }

    .infrastructure-text-1 {
        font-size: 16px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 40px !important;
        word-spacing: 0px !important;
    }

    .infrastructure-span {
        color: #29336A !important;
        font-size: 40px !important;
    }

    .infrastructure-text-2 {
        font-size: 16px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: -10px !important;
    } 

}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .infrastructure-img-text {
        margin-top: 60px;
        margin-left: 60px;
    }

    .infrastructure-img-1 {
        width: 90%;
        object-fit: contain ;
    }

    .infrastructure-img-2 {
        width: 90%;
        object-fit: contain;
        margin-left: 67px !important;
    }

    .infrastructure-text-container-1 {
        margin-top: 55px !important;
        margin-left: -70px !important;
    }

    .infrastructure-img-3 {
        width: 90% !important;
        object-fit: contain;
        margin-left: 63px !important;
        margin-top: 44px !important;
    }

    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain;
        margin-top: -90px !important;
        margin-left: 115px !important;
    }

    .infrastructure-text-1 {
        font-size: 16px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 40px !important;
        word-spacing: 0px !important;
    }

    .infrastructure-span {
        color: #29336A !important;
        font-size: 40px !important;
    }

    .infrastructure-text-2 {
        font-size: 16px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: -10px !important;
    } 
}



@media screen and (min-width: 1281px) and (max-width: 1366px) {
    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 37px !important;
        text-align: left !important;
        margin-left: 0px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }
    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    .infrastructure-img-text {
        margin-top: 100px !important; 
        margin-left: 45px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 40px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 37px !important;
        text-align: left !important;
        margin-left: 59px !important;
        word-spacing: 0px !important;
        margin-top: 20px;
    } 

    .infrastructure-img-4 {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: 21px !important;
        margin-left: 0px !important;
    }
    
}

@media screen and (min-width: 1366px) and (max-width: 1440px) {
    .infrastructure-text-1 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 37px !important;
        text-align: left !important;
        margin-left: 0px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }
    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    .infrastructure-img-text {
        margin-top: 100px !important; 
        margin-left: 50px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 40px;
    }

    .infrastructure-text-2 {
        font-size: 20px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 37px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: 20px;
    } 

    .infrastructure-img-4 {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -47px !important;
        margin-left: 0px !important;
    }
    
}


@media screen and (min-width: 1441px) and (max-width: 1600px) {
    .infrastructure-text-1 {
        font-size: 22px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        text-align: left !important;
        margin-left: 0px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }
    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    .infrastructure-img-text {
        margin-top: 100px !important; 
        margin-left: 50px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 45px !important;
    }

    .infrastructure-text-2 {
        font-size: 22px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: -10px !important;
    } 

    .infrastructure-img-4 {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -47px !important;
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .infrastructure-text-1 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 43px !important;
        text-align: left !important;
        margin-left: 0px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }
    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    .infrastructure-img-text {
        margin-top: 100px !important; 
        margin-left: 50px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 50px;
    }

    .infrastructure-text-2 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 43px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: -10px !important;
    } 

    .infrastructure-img-4 {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -47px !important;
        margin-left: 0px !important;
    } 
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
    .infrastructure-text-1 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 45px !important;
        text-align: left !important;
        margin-left: 0px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }
    .infrastructure-img-1 {
        width: 100% !important;
        object-fit: contain !important;
    }
    .infrastructure-img-text {
        margin-top: 100px !important; 
        margin-left: 50px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 55px !important;
    }

    .infrastructure-text-2 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 45px !important;
        text-align: left !important;
        margin-left: 60px !important;
        word-spacing: 0px !important;
        margin-top: -10px !important;
    } 

    .infrastructure-img-4 {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -47px !important;
        margin-left: 0px !important;
    } 
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    
    .infrastructure-text-1 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 50px !important;
        text-align: left !important;
        margin-left: -47px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }

    .infrastructure-span {
        color: #29336A !important;
        font-size: 60px !important;
    }

    .infrastructure-text-2 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 50px !important;
        text-align: left !important;
        margin-left: 105px !important;
        word-spacing: 0px !important;
        margin-top: 20px;
    } 
    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain;
        margin-top: -80px;
        margin-left: 140px;
    }

    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain !important;
        margin-top: -95px !important;
        margin-left: 67px !important;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .infrastructure-text-1 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 50px !important;
        text-align: left !important;
        margin-left: -47px !important;
        word-spacing: 0px !important;
        margin-top: 100px !important;
    }

    .infrastructure-span {
        color: #29336A !important;
        font-size: 60px !important;
    }

    .infrastructure-text-2 {
        font-size: 25px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 50px !important;
        text-align: left !important;
        margin-left: 105px !important;
        word-spacing: 0px !important;
        margin-top: 20px;
    } 
    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain;
        margin-top: -80px;
        margin-left: 140px;
    }

    .infrastructure-img-4 {
        width: 90% !important;
        object-fit: contain !important;
        margin-top: -95px !important;
        margin-left: 67px !important;
    }
}

@media screen and (min-width: 2561px) and (max-width: 3840px) {
    .infrastructure-text-1 {
        font-size: 27px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 47px !important;
        text-align: left !important;
        margin-left: 40px !important;
        word-spacing: 0px !important;
    }

    .infrastructure-span {
        color: #29336A;
        font-size: 152px;
    }

    .infrastructure-text-2 {
        font-size: 65px !important;
        font-family: 'Libre Baskerville', serif !important;
        font-weight: 400 !important;
        line-height: 95px !important;
        text-align: left !important;
        margin-left: 100px !important;
        word-spacing: 0px !important;
        margin-top: 50px;
    }
}