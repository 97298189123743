.footer-bottom-left-text{
    color: #232F6D !important;
    font-size: 20px !important;
    font-family: 'Libre Baskerville', serif !important; 
    margin-left: 50px !important;
}
.footer-bottom-right-text{
    color: #232F6D !important;
    font-size: 20px !important;
    font-family: 'Libre Baskerville', serif !important;  
    margin-right: 50px !important;
}

.company-link{
    text-decoration: none;
    color: #232F6D !important;
    font-size: 20px !important;
    font-family: 'Libre Baskerville', serif !important;
}

@media screen and (max-width: 500px) {
    .footer-bottom-left-text{
        color: #232F6D !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important; 
        margin-left: 0px !important;
        text-align: center !important;
    }
    .footer-bottom-right-text{
        color: #232F6D !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;  
        margin-right: 0px !important;
    }
    
    .company-link{
        text-decoration: none;
        color: #232F6D !important;
        font-size: 15px !important;
        font-family: 'Libre Baskerville', serif !important;
    }
    
}