.values-and-philosophy{
    padding-left: 200px;
    padding-right: 200px;
    margin-top: 130px !important;
}

.values-and-philosophy-heading{
    text-align: center;
    color: #316C2F;
    font-size: 70px;
    font-weight: 400;
    font-family: 'Libre Baskerville', serif; 
    margin-top: 40px !important;
}

.values-and-philosophy-content{
    padding-left: 100px;
    padding-right: 153px;
}

@media screen and (max-width: 325px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: left !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }
    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (min-width: 326px) and (max-width: 375px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }   
    
}

@media screen and (min-width: 376px) and (max-width: 480px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 28px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }   
    
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 50px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }   
    
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 70px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }   
    
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 35px !important;
    }   
    
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important ;
        font-weight: 400 !important;
    }
    

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 50px !important;
    }  
}


@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 90px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 55px !important;
    }   
    
}




@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 70px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 0px !important;
        padding-right: 15px !important;
    }  
    
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 130px !important ;
        font-weight: 400 !important;
    }

    .values-and-philosophy-content{
        padding-left: 52px !important;
        padding-right: 55px !important;
    }  
    
}

@media screen and (min-width: 2561px) and (max-width: 3840px) {
    .values-and-philosophy{
        padding-left: 27px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 0px !important;
    }
    
    .values-and-philosophy-heading{
        text-align: center !important;
        color: #316C2F !important;
        font-size: 200px !important ;
        font-weight: 400 !important;
    }

    
    .values-and-philosophy-content{
        padding-left: 52px !important;
        padding-right: 55px !important;
    }  
    
    
}